import * as React from 'react';

import clsx from 'clsx';

interface ContentAreaProps {
  className?: string;
  containerClassName?: string;
  containerStyle?: React.CSSProperties;
}

export const ContentArea: React.FunctionComponent<ContentAreaProps> = ({
  children,
  className,
  containerClassName,
  containerStyle,
}) => {
  return (
    <div
      className={clsx('flex min-w-0 justify-center', containerClassName)}
      style={containerStyle}
    >
      <div className={clsx('min-w-0 max-w-[1440px] flex-1', className)}>
        {children}
      </div>
    </div>
  );
};
