import * as React from 'react';

import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';

import clsx from 'clsx';
import { AnimatePresence, m } from 'framer-motion';

import TogetherrLogo from '/public/common/svg/togetherr-fiverr-company.svg';

import { useBasicDetails } from '~lib/hooks/use-basic-details';

import { Avatar } from '../avatar';
import { Button } from '../button';
import { ContentArea } from '../content-area';
import { SizeVariant } from '../types';

export enum Theme {
  Dark = 'Dark',
  Light = 'Light',
}

interface GuestHeaderProps {
  theme?: Theme;
}

const signupButtonTheme = {
  [Theme.Dark]: Button.Theme.White,
  [Theme.Light]: Button.Theme.Black,
};

const loginButtonTheme = {
  [Theme.Dark]: Button.Theme.White,
  [Theme.Light]: Button.Theme.Black,
};

const HEADER_HEIGHT = 88;

const NavLink: React.FC<LinkProps> = ({ children, href, ...props }) => {
  const router = useRouter();

  return (
    <Link href={href} {...props} passHref>
      <a
        className={clsx(
          'c100 w-full text-[14px]',
          'mt-1 border-b border-b-transparent pb-1 transition-colors duration-300 hover:border-white',
          router?.asPath === href && 'xl:!border-white',
        )}
      >
        {children}
      </a>
    </Link>
  );
};

const Home = () => <NavLink href="/">home</NavLink>;

const HowItWorks = () => <NavLink href="/how-it-works">how it works</NavLink>;

const ForBrands = () => <NavLink href="/for-brands">For brands</NavLink>;

const ForTalent = () => <NavLink href="/for-talent">For talent</NavLink>;

const WhoWeAre = () => <NavLink href="/who-we-are">Who we are</NavLink>;

const Blog = () => <NavLink href="/blog">Blog</NavLink>;

const Faq = () => <NavLink href="/faq">FAQ</NavLink>;

const SignIn = ({ theme, size = Button.Size.Small }) => (
  <Link href="/auth/login?sessionType=talent" passHref>
    <Button
      theme={loginButtonTheme[theme]}
      fill={Button.Fill.Ghost}
      size={size}
      className="w-full"
    >
      Log in
    </Button>
  </Link>
);
const SignUp = ({ theme, size = Button.Size.Small }) => (
  <Link href="/signup" passHref>
    <Button theme={loginButtonTheme[theme]} size={size} className="w-full">
      Sign up
    </Button>
  </Link>
);

export const GuestHeader: React.FunctionComponent<GuestHeaderProps> & {
  Theme: typeof Theme;
} = ({ theme = Theme.Dark }) => {
  const router = useRouter();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { isLoggedIn, fullName, avatarImageUrl, sessionType } =
    useBasicDetails();

  return (
    <header
      className={clsx('sticky top-0 z-30 flex flex-col', {
        'bg-black text-white': theme === Theme.Dark,
        'bg-white text-black': theme === Theme.Light,
      })}
    >
      <ContentArea>
        <nav
          className="flex items-center px-4 py-6"
          style={{ height: HEADER_HEIGHT }}
        >
          <ul className="flex flex-1 items-center space-x-8 whitespace-nowrap">
            <li className="flex items-center">
              <Link href="/">
                <a>
                  <TogetherrLogo className="h-10" />
                </a>
              </Link>
            </li>
            <li className="hidden xl:flex xl:items-center">
              <WhoWeAre />
            </li>
            <li className="hidden xl:flex xl:items-center">
              <HowItWorks />
            </li>
            <li className="hidden xl:flex xl:items-center">
              <ForBrands />
            </li>
            <li className="hidden xl:flex xl:items-center">
              <ForTalent />
            </li>
            <li className="hidden xl:flex xl:items-center">
              <Blog />
            </li>
            <li className="hidden xl:flex xl:items-center">
              <Faq />
            </li>
          </ul>
          {isLoggedIn ? (
            <div className="relative hidden min-w-[189px] xl:flex">
              <Button
                theme={signupButtonTheme[theme]}
                fill={Button.Fill.Full}
                className="h-[42px] min-w-[189px] justify-between pl-2 pr-4"
                onClick={() => {
                  router.push(sessionType);
                }}
              >
                <span className="flex items-center gap-2">
                  <Avatar
                    src={avatarImageUrl}
                    userName={fullName}
                    variant={SizeVariant.EXTRA_SMALL}
                    className="md:h-8 md:w-8"
                  />
                  <span>go to dashboard</span>
                  <span>→</span>
                </span>
              </Button>
            </div>
          ) : (
            <ul className="flex items-center space-x-4">
              <li className="hidden xl:block">
                <SignIn theme={theme} />
              </li>
              <li className="hidden xl:block">
                <SignUp theme={theme} />
              </li>
            </ul>
          )}
          <button
            className="ml-6 flex items-center xl:hidden"
            onClick={() => setIsMenuOpen((isOpen) => !isOpen)}
          >
            <svg
              className="h-10 w-10"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 40 20"
            >
              <AnimatePresence>
                {!isMenuOpen && (
                  <m.path
                    initial={{ opacity: 0, y: 7 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 7 }}
                    d="M-1.45705e-07 3.66667L40 3.66667V0.333337L1.45705e-07 0.333333L-1.45705e-07 3.66667Z"
                    fill="currentColor"
                  />
                )}
              </AnimatePresence>
              <m.path
                animate={isMenuOpen ? 'open' : 'closed'}
                variants={{
                  open: { y: -7 },
                  closed: { y: 0 },
                }}
                d="M-1.45705e-07 19.6667L40 19.6667V16.3333L1.45705e-07 16.3333L-1.45705e-07 19.6667Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </nav>
      </ContentArea>
      <AnimatePresence exitBeforeEnter initial={false}>
        {isMenuOpen && (
          <m.nav
            key="mobile-menu"
            initial="closed"
            animate="open"
            exit="closed"
            variants={{
              open: { height: window?.innerHeight - HEADER_HEIGHT, opacity: 1 },
              closed: { height: 0, opacity: 0 },
            }}
            className="z-30 flex flex-col xl:hidden"
          >
            <div className="flex flex-1 flex-col px-6 xs:pt-6">
              <ul className="flex flex-1 flex-col gap-10 pb-4">
                <li className="flex">
                  <Home />
                </li>
                <li className="flex">
                  <WhoWeAre />
                </li>
                <li className="flex">
                  <HowItWorks />
                </li>
                <li className="flex">
                  <ForBrands />
                </li>
                <li className="flex">
                  <ForTalent />
                </li>
                <li className="flex">
                  <Blog />
                </li>
                <li className="flex">
                  <Faq />
                </li>
              </ul>
              <footer
                className={clsx(
                  'flex flex-col items-center justify-between border-t py-8 md:flex-row',
                  {
                    'border-black': theme === Theme.Light,
                    'border-white': theme === Theme.Dark,
                  },
                )}
              >
                {isLoggedIn ? (
                  <div className="flex min-w-full xl:hidden">
                    <Button
                      theme={signupButtonTheme[theme]}
                      fill={Button.Fill.Full}
                      className="h-[42px] min-w-full justify-between pl-2 pr-4"
                      onClick={() => router.push(sessionType)}
                    >
                      <span className="flex items-center gap-2">
                        <Avatar
                          src={avatarImageUrl}
                          userName={fullName}
                          variant={SizeVariant.EXTRA_SMALL}
                          className="md:h-8 md:w-8"
                        />
                        <span className="">go to dashboard</span>
                      </span>
                      <span>→</span>
                    </Button>
                  </div>
                ) : (
                  <>
                    <div className="flex w-full justify-between gap-x-4 md:w-min">
                      <SignIn size={Button.Size.Large} theme={theme} />
                      <SignUp size={Button.Size.Large} theme={theme} />
                    </div>
                  </>
                )}
              </footer>
            </div>
          </m.nav>
        )}
      </AnimatePresence>
    </header>
  );
};

GuestHeader.Theme = Theme;
