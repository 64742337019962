import * as React from 'react';

import Link from 'next/link';

import TogetherrLogo from '/public/common/svg/togetherr-fiverr-company.svg';

import clsx from 'clsx';

import { dispatchAnalyticsEvent, Events } from '~lib/loggers/events-recorder';

import { Button } from './button';
import { ContentArea } from './content-area';

export const GuestFooter: React.FunctionComponent<{
  className?: string;
}> = ({ className }) => {
  return (
    <footer className={clsx('bg-black text-white', className)}>
      <ContentArea className="px-4 py-8 md:flex md:items-start md:justify-between md:px-12 md:pr-30 md:pt-14 md:pb-8 lg:px-14">
        <div>
          <TogetherrLogo className="h-10" />
          <div className="mt-6">
            <Link href="/project-setup" passHref>
              <Button
                size={Button.Size.Large}
                theme={Button.Theme.White}
                className="hidden lg:flex"
                onClick={() => {
                  dispatchAnalyticsEvent({
                    event: Events.START_PROJECT,
                    position: 'footer',
                  });
                }}
              >
                start a project →
              </Button>
            </Link>
            <Link href="/signup" passHref>
              <Button
                size={Button.Size.Large}
                theme={Button.Theme.White}
                className="lg:hidden"
              >
                sign up →
              </Button>
            </Link>
            <p className="mt-4 text-black opacity-50">
              © togetherr {new Date().getFullYear()}
            </p>
          </div>
        </div>
        <ul className="mt-6 grid grid-cols-2 grid-rows-4 gap-y-2 gap-x-6 tracking-tight md:mt-0 md:inline-grid md:grid-flow-col-dense md:gap-x-11 lg:gap-x-32 lg:gap-y-4">
          <li>
            <Link href="/who-we-are">About</Link>
          </li>
          <li>
            <Link href="/terms">Terms of service</Link>
          </li>
          <li>
            <a href="mailto:support@togetherr.com?subject=Hey togetherr team!">
              Contact us
            </a>
          </li>
          <li>
            <Link href="/faq">FAQ</Link>
          </li>
          <li>
            <Link href="/demo">Book a demo</Link>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/togetherr/"
              target="_blank"
              rel="noreferrer"
            >
              Linkedin
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/togetherrcom"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/togetherrcreative/"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCyghIS-B4GUstWFf1h1r-Iw"
              target="_blank"
              rel="noreferrer"
            >
              YouTube
            </a>
          </li>
          <li>
            <Link href="/privacy" passHref>
              Privacy policy
            </Link>
          </li>
        </ul>
      </ContentArea>
    </footer>
  );
};
