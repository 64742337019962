import { css } from '@emotion/css';
import clsx from 'clsx';

export const Loading = ({ className = 'w-16' }: { className?: string }) => {
  return (
    <div
      className={clsx(
        className,
        css`
          .nc-loop-dots-5-16-icon-f {
            --animation-duration: 1s;
          }
          .nc-loop-dots-5-16-icon-f * {
            animation: nc-loop-dots-5-anim var(--animation-duration) infinite;
          }
          .nc-loop-dots-5-16-icon-f :nth-child(1) {
            transform-origin: 8px 3px;
          }
          .nc-loop-dots-5-16-icon-f :nth-child(2) {
            transform-origin: 13px 8px;
            animation-delay: 0.1s;
          }
          .nc-loop-dots-5-16-icon-f :nth-child(3) {
            transform-origin: 8px 13px;
            animation-delay: 0.2s;
          }
          .nc-loop-dots-5-16-icon-f :nth-child(4) {
            transform-origin: 3px 8px;
            animation-delay: 0.3s;
          }
          @keyframes nc-loop-dots-5-anim {
            0%,
            100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.4;
              transform: scale(0.7);
            }
          }
        `,
      )}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <g className="nc-icon-wrapper" fill="currentColor">
          <g className="nc-loop-dots-5-16-icon-f">
            <circle cx="8" cy="3" fill="currentColor" r="2"></circle>
            <circle cx="13" cy="8" r="2" data-color="color-2"></circle>
            <circle cx="8" cy="13" fill="currentColor" r="2"></circle>
            <circle cx="3" cy="8" r="2" data-color="color-2"></circle>
          </g>
        </g>
      </svg>
    </div>
  );
};
