import React, { SyntheticEvent } from 'react';

import Image from 'next/image';

import clsx from 'clsx';

import { getBlurImageUrl, imageLoader, isSupportBlur } from '~lib/image-loader';

import { SizeVariant } from '../types';

const variantMap = {
  [SizeVariant.EXTRA_EXTRA_SMALL]: `w-6 h-6`,
  [SizeVariant.EXTRA_SMALL]: `w-8 h-8 md:w-10 md:h-10`,
  [SizeVariant.SMALL]: `w-8 h-8 md:w-12 md:h-12`,
  [SizeVariant.MEDIUM]: `w-16 h-16`,
  [SizeVariant.LARGE]: `w-[8.75rem] h-[8.75rem] md:w-40 md:h-40`,
};

interface AvatarProps {
  className?: string;
  src: string;
  userName?: string;
  variant?: SizeVariant;
  bannerLogo?: string;
  onImageLoad?: (e: SyntheticEvent<HTMLImageElement, Event>) => void;
}

export const DEFAULT_AVATAR_IMAGE = '/common/svg/missing-avatar.svg';

const validateSrc = (src: string) =>
  (src && src?.startsWith('/')) || (src?.includes('http') && src.length > 0);

export const Avatar = ({
  src = DEFAULT_AVATAR_IMAGE,
  userName = 'user',
  variant = SizeVariant.SMALL,
  className,
  bannerLogo,
  onImageLoad,
}: AvatarProps) => {
  const _src = validateSrc(src) ? src : DEFAULT_AVATAR_IMAGE;
  return (
    <React.Fragment>
      <div
        className={clsx(
          className,
          'overflow-hidden rounded-full border border-layout',
          variantMap[variant],
        )}
      >
        <Image
          className="h-full w-full object-cover object-center"
          src={_src}
          loader={imageLoader}
          placeholder={isSupportBlur(_src) ? 'blur' : 'empty'}
          blurDataURL={getBlurImageUrl(_src)}
          alt={`avatar image of ${userName}`}
          height="316"
          width="316"
          crossOrigin="anonymous"
          onLoad={onImageLoad}
        />
      </div>
      {bannerLogo && (
        <div className="banner absolute bottom-0 right-0 hidden h-[48px]">
          <Image
            src={bannerLogo}
            loader={imageLoader}
            placeholder={isSupportBlur(bannerLogo) ? 'blur' : 'empty'}
            blurDataURL={getBlurImageUrl(bannerLogo)}
            alt={`${userName}'s branded team`}
            height="48"
            width="48"
          />
        </div>
      )}
    </React.Fragment>
  );
};
