import React from 'react';

import clsx from 'clsx';

import { Loading } from '../loading/loading';

export enum ButtonFill {
  Ghost = 'Ghost',
  Full = 'Full',
  None = 'None',
}

export enum ButtonSize {
  Small = 'Small',
  Large = 'Large',
}

export enum ButtonShape {
  Round = 'Round',
  Square = 'Square',
}

export enum ButtonTheme {
  Black = 'Black',
  White = 'White',
}

export interface NewButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement | HTMLAnchorElement>, 'size'> {
  theme?: ButtonTheme;
  fill?: ButtonFill;
  size?: ButtonSize;
  shape?: ButtonShape;
  disabled?: boolean;
  href?: string;
  loading?: boolean;
}

export interface CompoundNewButton
  extends React.ForwardRefExoticComponent<NewButtonProps> {
  Theme: typeof ButtonTheme;
  Fill: typeof ButtonFill;
  Size: typeof ButtonSize;
  Shape: typeof ButtonShape;
}

export const Button = React.forwardRef<any, NewButtonProps>(
  (
    {
      children,
      className,
      fill = ButtonFill.Full,
      size = ButtonSize.Small,
      theme = ButtonTheme.Black,
      shape = ButtonShape.Round,
      disabled = false,
      href,
      type = 'button',
      loading,
      ...props
    },
    ref,
  ) => {
    const isBlackFull = theme === ButtonTheme.Black && fill == ButtonFill.Full;
    const isBlackGhost =
      theme === ButtonTheme.Black && fill == ButtonFill.Ghost;
    const isBlackNoFill =
      theme === ButtonTheme.Black && fill == ButtonFill.None;
    const isWhiteFull = theme === ButtonTheme.White && fill == ButtonFill.Full;
    const isWhiteGhost =
      theme === ButtonTheme.White && fill == ButtonFill.Ghost;
    const isWhiteNoFill =
      theme === ButtonTheme.White && fill == ButtonFill.None;

    return React.createElement(
      href ? 'a' : 'button',
      {
        disabled,
        className: clsx(
          'l100-b !appearance-none focus:outline-none whitespace-nowrap lowercase cursor-pointer inline-flex items-center justify-center border transition duration-300 ease-in-out disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400',
          [
            isBlackFull &&
              'border-transparent bg-black text-white hover:bg-opacity-80 dark:bg-white dark:text-black',
            isBlackGhost &&
              'border-layout bg-transparent text-black hover:bg-black hover:text-white dark:text-white dark:border-white dark:hover:text-black dark:hover:bg-white',
            isBlackNoFill &&
              'border-transparent bg-transparent text-black hover:border-black',
            isWhiteFull &&
              'border-transparent bg-white text-black dark:bg-white dark:text-black hover:bg-transparent hover:text-white hover:border-white',
            isWhiteGhost &&
              'border-white bg-transparent text-white hover:bg-white hover:text-black',
            isWhiteNoFill && 'border-transparent bg-transparent text-white',
            size === ButtonSize.Small && 'py-3 px-6',
            size === ButtonSize.Large && 'py-4 px-10',
            shape === ButtonShape.Round && 'rounded-full',
            shape === ButtonShape.Square && 'rounded-none',
          ],
          className,
        ),
        href,
        ref,
        type,
        ...props,
      },
      loading ? <Loading className="mx-4 h-4 w-4" /> : children,
    );
  },
) as CompoundNewButton;

Button.Theme = ButtonTheme;
Button.Fill = ButtonFill;
Button.Size = ButtonSize;
Button.Shape = ButtonShape;
