import { ImageLoader } from 'next/image';

const CLOUDINARY_DOMAIN = 'res.cloudinary.com';
const FILESTACK_DOMAIN = 'cdn.filestackcontent.com';
const BUILDER_IO_DOMAIN = 'cdn.builder.io';

export const imageLoader: ImageLoader = ({ src, quality, width }) => {
  // Cloudinary Optimization - https://cloudinary.com/blog/the_holy_grail_of_image_optimization_or_balancing_visual_quality_and_file_size
  if (src.includes(CLOUDINARY_DOMAIN)) {
    const url = src.split('/image/upload');
    return `${url[0]}/image/upload/c_limit,w_${width}/q_${
      quality || 'auto:eco'
    }/f_auto/${url[1]}`;
  }

  // FileStack Optimization - https://www.filestack.com/docs/transformations/overview/
  if (src.includes(FILESTACK_DOMAIN)) {
    const handler = src.split(FILESTACK_DOMAIN + '/')?.[1];
    return `https://cdn.filestackcontent.com/resize=width:${width},fit:max/quality=value:${
      quality || 70
    }/auto_image/compress/${handler}`;
  }

  // Builder.io Optimization - https://forum.builder.io/t/how-to-use-images-from-data-models-in-next-js/1838
  if (src.includes(BUILDER_IO_DOMAIN)) {
    return `${src}?width=${width}&quality=${quality || 75}`;
  }

  // Image served from public folder
  if (src.includes('https://')) {
    return `/_next/image?${new URLSearchParams({
      url: src,
      w: width?.toString(),
      q: quality?.toString() || '75',
    })}`;
  }

  return src;
};

export const isSupportBlur = (src: string): boolean => {
  return src?.includes(CLOUDINARY_DOMAIN) || src?.includes(FILESTACK_DOMAIN);
};

export const getBlurImageUrl = (src: string): string => {
  if (src?.includes(CLOUDINARY_DOMAIN)) {
    const url = src.split('/image/upload');
    return `${url[0]}/image/upload/f_auto,e_blur:1000,q_1/${url[1]}`;
  }
  if (src?.includes(FILESTACK_DOMAIN)) {
    const handler = src.split(FILESTACK_DOMAIN + '/')?.[1];
    return `https://cdn.filestackcontent.com/blur=amount:10/quality=value:10/auto_image/compress/${handler}`;
  }

  return null;
};
